:root {
  --background: rgba(153, 102, 255, .85)
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #fff;
  font-weight: 400;
}

header {
  background: var(--background);
  text-align: center;
  position: fixed;
  z-index: 999;
  width: 100%;
}

.nav-Menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: var(--background);
  width: 100%;
  transform: scale(1,0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

.nav-Menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-Menu li {
  margin-bottom: 1em;
  margin-left: 1em;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 500;
  font: size 16px;
  color: rgb(0,0,0);
  text-decoration: none;
}

.nav-Menu a{
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.nav-Menu a:hover {
  color: black;
}

.nav-links li{
  
  padding: 0 20px;
}

.nav-toggle:checked ~ nav {
  transform: scale(1,1);
}

.nav-toggle:checked ~ nav a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 150ms;
}

@media screen and (min-width: 600px) {
  header{
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
  }
  .logo {
    grid-column: 1/span 1;
  }
  .nav-Menu {
    all: unset;
    grid-column: 3/4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .nav-Menu ul{
    display: flex;
  }

  .nav-Menu li {
    margin-left: 3em;
    margin-bottom: 0;
  }

  .nav-Menu a {
    opacity: 1;
    position: relative;
  }

  .nav-Menu a::before {
    content: '';
    display: block;
    height: 5px;
    background: black;
    position: absolute;
    bottom: -.5em;
    left: 0;
    right: 0;
    transform: scale(0,1);
    transition: transform ease-in-out 150ms;
  }

  .nav-Menu a:hover::before {
    transform: scale(1,1);
  }
}